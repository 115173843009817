import HttpUtil from '@/utils/HttpUtil';

export default {
  // 获取应用打印模板列表
  getReportList(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/print/query/list';
    return HttpUtil.post(url, params);
  },

  /**
   * 根据id获取打印模板详情
   * @param {string} applicationPrintId
   * @return { ReportModel } obj
   */
  getReportDetail(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/print/query/single';
    return HttpUtil.get(url, params);
  },

  /**
   * 根据模板选中的关联对象，获取可配置的映射项
   * @param {string} applicationLinkId
   * @return { ReportModel } obj
   */
  getReportLinkFields(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/print/query/data/options';
    return HttpUtil.get(url, params);
  },

  /**
   * 根据模板选中的关联对象，获取可配置的映射项
   * @param {string} applicationPrintId
   * @return { ReportModel } obj
   */
  getReportFileFields(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/print/query/data/options';
    return HttpUtil.get(url, params);
  },

  /**
   * 保存打印模板
   * @param {ReportModel} ReportModel
   * @return {ReportModel} ReportModel
   */
  saveReport(params) {
    // let url = process.env.VUE_APP_SERVER_CODE + 'application/print/save';
    let url = process.env.VUE_APP_SERVER_CODE + 'application/print/addOrUpdate';
    return HttpUtil.post(url, params);
  },

  /**
  * 复制打印模板
  * @param {ReportModel} ReportModel
  * @return {ReportModel} ReportModel
  */
  copyReport(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/print/copy';
    return HttpUtil.post(url, params);
  },

  /**
   * 根据id获取打印模板详情
   * @param {string} applicationPrintId
   */
  removeReport(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/print/delete';
    return HttpUtil.get(url, params);
  },

  /**
   * 获取打印内容
   * @param {string} applicationPrintId
   */
  printReport(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/print/pdf';
    return HttpUtil.preview(url, params);
  }

};
