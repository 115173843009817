// 流程操作类型
export const FlowOperationList: Record<string, string>[] = [
  {
    name: 'lang_flow_log_status_temporary_storage',
    id: '-1'
  },
  {
    name: 'lang_flow_log_status_launch',
    id: '1'
  },
  {
    name: 'lang_flow_log_status_arrive',
    id: '2'
  },
  {
    name: 'lang_flow_log_status_submit',
    id: '3'
  },
  {
    name: 'lang_flow_log_status_agree',
    id: '4'
  },
  {
    name: 'lang_flow_log_status_turndown',
    id: '5'
  },
  {
    name: 'lang_flow_log_status_withdraw',
    id: '6'
  },
  {
    name: 'lang_flow_log_status_termination',
    id: '7'
  },
  {
    name: 'lang_flow_log_status_automatic',
    id: '8'
  },
  {
    name: 'lang_flow_log_status_system',
    id: '9'
  },
  {
    name: 'lang_flow_log_status_finish',
    id: '0'
  }
];
