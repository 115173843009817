









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ReportApi from '@/api/report-design/ReportApi';
import DateUtil from 'global-ui/packages/utils/DateUtil';
import EleUpload from '../form-design/elements/ele-upload.vue';

@Component({
  name: 'ReportList',
  components: { EleUpload }
})
export default class ReportList extends Vue {
  // 应用id
  @Prop({ default: '' }) linkId!: string;

  // 应用id
  @Prop({ default: '' }) dataId!: string;

  @Prop({ default: false })
  visible!: boolean; // 流程设置弹框 的显示隐藏
  dialogVisiable: boolean = false; // 是否显示页面
  DateUtil: any = DateUtil;
  // 打印配置列表
  reportList: any[] = [];
  created(): void {
    this.initData();
  }

  initData() {
    this.getReportList();
  }

  /**
   * 获取打印模板
   */
  getReportList() {
    let params = { linkId: this.linkId };
    ReportApi.getReportList(params).then((res: any) => {
      if (res.code == '1' && res.data) {
        this.reportList = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 打印
   */
  print(row) {
    let params = {
      applicationPrintId: row.id,
      dataId: this.dataId
    };
    ReportApi.printReport(params);
  }

  /**
   * 关闭弹窗
   */
  handleColseDialog() {
    this.$emit('update:visible', false);
  }

  @Watch('visible')
  visibleWatcher(val) {
    // 弹窗显示
    if (val) {
      // 获取表单列表
      this.dialogVisiable = val;
      this.initData();
    } else {
      this.dialogVisiable = val;
    }
  }
}
